function Player({handleCloseButtonClick}) {

  return (
      <div className="modal fade" id="video-modal" tabIndex="-1" role="dialog" aria-labelledby="video-modal-label" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="video-modal-label">Video of the game</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseButtonClick}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <iframe title="Videos" width="100%" height="415" src="" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Player;