import foot from './foot.svg';

function Navbar({ onlyLoadDataFromApi, handleSearchFormSubmit, handleClick, handleHomeClick, handleToggle, expanded }) {

  
  return (
    <nav className="navbar sticky-top navbar-expand-lg navbar-dark" style={{ backgroundColor: "#262546" }}>
      <div className="navbar-brand" onClick={() => {
        onlyLoadDataFromApi();
        handleHomeClick();
      }}>
        <img src={foot} className="foot-app" alt="Watch Recent Football Games" />
      </div>
      <button className="navbar-toggler" type="button" onClick={handleToggle} data-toggle="collapse" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`}>
        <ul className="navbar-nav mr-auto">
          <li className="nav-item active">
            <a className="nav-link" href="#Home" onClick={() => {
              onlyLoadDataFromApi();
              handleHomeClick();
            }}>
              <div className="home">
                <i className="fa-solid fa-house fa-md" style={{ color: "#fff" }}></i>
              </div>
              <span className="sr-only">(current)</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#About" onClick={handleClick}>About</a>
          </li>
        </ul>
        <form id="search-form" className="form-inline my-2 my-lg-0" onSubmit={handleSearchFormSubmit}>
          <input id="search-input" className="form-control mr-sm-2" type="search" placeholder="Search your team" aria-label="Search" />
          <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
        </form>
      </div>
    </nav>
  );
}

export default Navbar;
