function Videos({videos, handlePlayButtonClick}) {
    
    return (
            videos.map((video, index) => (
                <div key={index} className="col-12 col-sm-6 col-md-4">
                    <div className="card" onClick={() => handlePlayButtonClick(video.videos[0].embed)}>
                        <img className="card-img-top" src={video.thumbnail} alt={video.title} />
                        <div className="card-body">
                            <h5 className="card-title">{video.title}</h5>
                            <p className="card-text">{video.competition.name}</p>
                            <p className="card-text">{new Date(video.date).toLocaleString()}</p>
                            <div className="play-button" data-video={video.videos[0].embed}>
                                <i className="fa fa-play-circle"></i>
                            </div>
                        </div>
                    </div>
                </div>
            ))
        );
}

export default Videos;
