import React, { useState, useEffect } from 'react';
import './App.css';
import $ from 'jquery';
import Navbar from './Navbar.js';
import Footer from './Footer.js';
import Results from './Results';
import Player from './Player.js';
import TopVideos from './TopVideos.js';

function App() {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState([]);
  const [topvideos, setTopvideos] = useState([]);
  const [expanded, setExpanded] = useState(false);


  useEffect(() => {
    onlyLoadDataFromApi();
  }, []);

  useEffect(() => {
    handleTopVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videos]);

  const onlyLoadDataFromApi = () => {
    setLoading(true);
    const apiUrl = "https://www.scorebat.com/video-api/v1/";
    const settings = {
      "async": true,
      "crossDomain": true,
      "url": apiUrl,
      "method": "GET"
    };
    $.ajax(settings).done(function (response) {
      setSearching(false);
      setLoading(false);
      setVideos(response);
    });
  };

  const handlePlayButtonClick = (videoUrl) => {
    var videoEmbed = videoUrl;
    let videoEmbedUrl;

    const srcRegex = /<iframe.*?src=['"](.*?)['"]/;
    const srcMatch = videoEmbed.match(srcRegex);

    if (srcMatch) {
        const src = srcMatch[1];
        videoEmbedUrl = src;
    }

    window.$('#video-modal').find('iframe').attr('src', videoEmbedUrl);
    window.$('#video-modal').modal('show');
  };


  const handleCloseButtonClick = () =>{
    $('#video-modal').find('iframe').attr('src', '');
  };

  const handleSearchFormSubmit = (event) => {
    event.preventDefault();
    const searchTerm = $('#search-input').val().toLowerCase();
    let allVideos=[];
    if (searchTerm === ''){
        onlyLoadDataFromApi();
    }else{
      videos.forEach((video)=> {
        const title = video.title.toLowerCase();
        if (title.includes(searchTerm)) {
          allVideos.push(video);
        }
      });
      setSearching(true);
      setResults(allVideos);
    }
    setExpanded(false);
  };

  const handleTopVideos = () => {
    var allTopTeams = ["Barcelona", "Real Madrid", "Manchester United", "Liverpool", 
    "Bayern Munich", "Juventus", "Paris Saint-Germain", "Manchester City", "Arsenal",
    "Chelsea", "Borussia Dortmund", "Atletico Madrid", "AC Milan", "Brazil", "Argentina", 
    "Germany", "Spain", "Italy", "France", "Netherlands", "Uruguay", "England", 
    "Portugal", "Belgium", "Mexico"];
  
    let top =[];
  
    if (videos.length > 0) {
      videos.forEach((video)=> {
        const title = video.title.toLowerCase();
        allTopTeams.forEach((team)=> {
          if (title.includes(team.toLowerCase())) {
            top.push(video);
          }
        })
      });
    }

    const allTop = top.filter((value, index, self) => {
      return self.findIndex(item => item.title === value.title && item.date === value.date) === index;
    });

    setTopvideos(allTop);
  };

  const handleHomeClick = () => {
    !loading && videos.length > 0 && topvideos.length > 0 &&window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  
  const handleClick = (e) => {
    e.preventDefault();
    const href = e.target.getAttribute('href');
    const element = document.querySelector(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleToggle = () => {
    setExpanded(!expanded);
  };
  

  const allVideosOrResults = () => searching ? results : videos;
  
  return (
    <div className="container mt-0">
      <Navbar 
        onlyLoadDataFromApi={onlyLoadDataFromApi}
        handleSearchFormSubmit={handleSearchFormSubmit}
        handleClick={handleClick}
        handleHomeClick={handleHomeClick}
        handleToggle={handleToggle}
        expanded={expanded}/>

      <TopVideos 
          searching={searching}
          loading={loading}
          topvideos={topvideos}
          handlePlayButtonClick={handlePlayButtonClick}/>

      <Results 
        searching={searching}
        loading={loading}
        onlyLoadDataFromApi={onlyLoadDataFromApi}
        allVideosOrResults={allVideosOrResults}
        handlePlayButtonClick={handlePlayButtonClick}/>

      <Player handleCloseButtonClick={handleCloseButtonClick}/>
      <Footer/>
    </div>
  );
}

export default App;