function Footer() {

  return (  
      <footer className="w-100 py-4 flex-shrink-0" id="About">
        <div className="container py-4">
            <div className="row gy-4 gx-5">
                <div className="col-lg-4 col-md-6 mb-3">
                    <h5 className="h1 text-white">Football Highlights.</h5>
                    <p className="small text-muted">Football highlights are a condensed version of a football match that showcases the most exciting and important moments of the game.</p>
                    <p className="small text-muted mb-0">&copy; Copyrights. All rights reserved {new Date().getFullYear()}.</p>
                </div>
                <div className="col-lg-4 col-md-12">
                    <h5 className="text-white mb-3">Contact</h5>
                    <ul className="list-unstyled text-muted">
                        <li>doodzerj@gmail.com</li>
                        <li>contact@footballhighlights.org</li>
                    </ul>
                </div>
                <div className="col-lg-4 col-md-6">
                    <h5 className="text-white mb-3">Why this website ?</h5>
                    <p className="small text-muted">Allow football fans to watch all the games that they may missed. I use an Scorebat and Youtube API for videos.</p>
                </div>
            </div>
        </div>
    </footer>
  );
}

export default Footer;