import Videos from './Videos';

function TopVideos({searching, loading, topvideos, handlePlayButtonClick}) {
    
    return (
        !searching && !loading && topvideos.length > 0 && (
            <div>
                <h1 className="h5 text-white mt-2">Top of the moment</h1>
                <div className="row mt-4">
                    <Videos videos={topvideos} handlePlayButtonClick={handlePlayButtonClick}/>
                </div>
            </div>
        )
    );
}

export default TopVideos;
