import Videos from './Videos';
import $ from 'jquery';

function Results({searching, loading, onlyLoadDataFromApi, allVideosOrResults, handlePlayButtonClick}) {
  
  return (
    <div>
      {(searching && $('#search-input').val().length > 0) ? (
        <div>
          <button className="btn btn-light my-2 my-sm-0" onClick={onlyLoadDataFromApi}>Reset</button>
          <h1 className="h5 text-white mt-2">Results for "{$('#search-input').val()}"</h1>
        </div>
      ) : <h1 className="h5 text-white mt-2">All others games</h1>}
      <div className="row mt-4">
        {loading ? (
          <div className="loader-container col-12">
            <div className="spinner"></div>
          </div>
        ) : (
          <>
            {allVideosOrResults().length > 0 ? (
              <Videos videos={allVideosOrResults()} handlePlayButtonClick={handlePlayButtonClick}/>
            ) : (
              <div>
                <h5 className="nothing-found ml-3">No videos found</h5>
              </div>
            )}
          </>
        )}
      </div>
      <div id="search-results"></div>
    </div>
  );
}

export default Results;
